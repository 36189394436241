<template>
    <div class="import_detail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small" label-width="85px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-input v-model="form.deptGroupName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-input v-model="form.departmentName" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>
            <PickupReturnAudit ref="PickupReturnAudit" :have-review="false" :have-reject="false" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border style="width: 100%" :data="tableData">
                <el-table-column label="名称" width="240" prop="name" />
                <el-table-column label="手机号" width="160" prop="mobile" />
                <el-table-column label="等级" width="120" prop="level">
                    <template slot-scope="scope">
                        {{ handleFindLevelName(scope.row) }}
                    </template>
                </el-table-column>
                <el-table-column label="折扣" width="120" prop="discount" />
                <el-table-column label="付款方式" width="120" prop="paymentFlag">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.paymentFlag" disabled size="mini">
                            <el-option
                                v-for="payType in meta.payType"
                                :label="payType.template.name"
                                :key="payType.template.flag"
                                :value="payType.template.flag"
                            />
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="余额" width="120" prop="money">
                    <template slot-scope="scope">{{ (scope.row.money / 10000).toFixed(2) }}</template>
                </el-table-column>
                <el-table-column label="推荐人" width="120" prop="developName" />
                <el-table-column label="推荐人手机号" width="120" prop="developMobile" />
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="page.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>
<script>
import Utils from '../../../../js/Util';
import PickupReturnAudit from 'components/PickupReturnAudit';

export default {
    name: 'ImportDetail',
    props: ['form'],
    components: { PickupReturnAudit },
    data() {
        return {
            tableData: [],
            page: {
                page: 1,
                limit: 50,
                total: 0,
                pageSizes: Utils.PageSizes,
                PageStyle: Utils.PageStyle,
            },
            meta: {
                payType: [],
                levels: [],
            },
            url: {
                baseUrl: '/member/import',
                page: '/member/import/detail/page',
                queryPayType: '/finance/pay/payType/page',
                queryDeptGroupLevel: '/member/level/queryDeptGroupMemberLevel',
            },
        };
    },
    filters: {
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '0') {
                return '待售';
            }
            if (status == '1') {
                return '正常';
            }
            if (status == '2') {
                return '丢失';
            }
            if (status == '3') {
                return '作废';
            }
        },
    },
    mounted() {
        const _this = this;
        _this.handleFindLevel();
        _this.handleQuery();
        _this.handleChangeDept();
    },
    methods: {
        handleFindLevel() {
            const _this = this;
            const _params = { params: { deptGroup: _this.form.deptGroupCode } };
            Utils.queryTable(_this, _this.url.queryDeptGroupLevel, _params, (data) => {
                _this.meta.levels = data.data;
            });
        },
        handleFindLevelName(val) {
            for (let j = 0; j < this.meta.levels.length; j++) {
                const level = this.meta.levels[j];
                if (val.level === level.level) {
                    return level.name;
                }
            }
        },
        handleChangeDept() {
            const _this = this;
            if (_this.form.deptCode) {
                const _params = { params: { enable: true, deptCode: _this.form.deptCode } };
                Utils.queryTable(_this, _this.url.queryPayType, _params, (data) => {
                    data.data = data.data || [];
                    data.data.forEach((v, i) => {
                        if (v.template.canRecharge) {
                            this.meta.payType.push(v);
                        }
                    });
                });
            }
        },
        handleSizeChange(size) {
            this.page.page = 1;
            this.page.limit = size;
            this.handleQuery();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.handleQuery();
        },
        handlePrevClick(page) {
            this.page.page = page;
            this.handleQuery();
        },
        handleNextClick(page) {
            this.page.page = page;
            this.handleQuery();
        },
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.form.code, this.url.baseUrl);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: { bizCode: _this.form.code, page: this.page.page, limit: _this.page.limit } };
            Utils.queryTable(_this, _this.url.page, _params);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
